body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  margin-right: 1em;
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group h3,
.form-group h4 {
  margin-top: 2rem;
}

.form-container h3 {
  width: 100%;
}
.form-container h4 {
  width: 100%;
}

.form-group .nested-form-group {
  margin-left: 1rem;
}

.button-container {
  margin-top: 1em;
}
.button-container button {
  font-size: 1em;
  padding: 5px 2em;
}

.loader{
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  height: 100%;
  width: 100%;
}
.button-container{
  
}